import React from 'react';
/* --- import Components ----*/
import {
  BreakdownSection,
  BreakdownSectionText,
  BreakdownSectionTitle,
  LineDescriptionEnd,
  LineDescriptionStart,
} from '../elements';
import {
  PlainLineSmall,
  RegularDottedLineSmall,
} from 'src/components/Bootcamp/Schedule/TypicalDay';
import { TagContainerNotCenter } from 'src/components/Tags/elements';
import Tag from 'src/components/Tags/Tag';
/* --- import Styles and Images ----*/
import Rocket from 'src/data/images/new_rocket.svg';
import { useMediaQueries } from 'src/utils/MediaQueries';
import colors from 'src/style/colors';
import BreakdownBoxes from './BreakdownBoxes';

const SmallBreakdown = ({ data }) => {
  const mqs = useMediaQueries();
  return (
    <div
      css={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '70px',
      }}
    >
      {data.map((section) => {
        const { sectionName, sectionDescription, sectionTitle, tags, sectionText } = section;
        return (
          <section
          key={sectionName}
            css={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'left',
              justifyContent: 'flex-start',
              position: 'relative',
              '&:after': {
                content: '""',
                position: 'absolute',
                width: 15,
                height: 15,
                left: -5,
                top: 0,
                border: `4px solid ${colors.yellow}`,
                backgroundColor: colors.purpleDark,
                borderRadius: '50%',
                zIndex: 1,
              },
            }}
          >
            {sectionName === 'bootcamp' ? (
              <PlainLineSmall left={false} thin />
            ) : (
              <RegularDottedLineSmall lineStyled="start" />
            )}
            {sectionName === 'bootcamp' ? (
              <LineDescriptionStart>{sectionDescription}</LineDescriptionStart>
            ) : null}
            <BreakdownSection>
              <BreakdownSectionTitle>{sectionTitle}</BreakdownSectionTitle>
              <TagContainerNotCenter landing css={{ margin: '20px 0 10px 0' }}>
                <Tag color={tags[0].color} spacer>
                  {tags[0].title}
                </Tag>
                <Tag color={tags[1].color} spacer>
                  {tags[1].title}
                </Tag>
              </TagContainerNotCenter>
              <TagContainerNotCenter landing css={{ marginBottom: '20px' }}>
                <Tag color={tags[2].color} spacer>
                  {tags[2].title}
                  {/* Du lundi{mqs.phoneOnly ? <br></br> : '  '}au vendredi */}
                </Tag>
                <Tag color={tags[3].color}>
                  {tags[3].title}
                  {/* En présentiel (Paris){mqs.phoneOnly ? <br></br> : '  '}ou à distance */}
                </Tag>
              </TagContainerNotCenter>
              <BreakdownSectionText>{sectionText}</BreakdownSectionText>
              <BreakdownBoxes section={sectionName} />
            </BreakdownSection>
            {sectionName === 'alternance' ? (
              <div>
                <LineDescriptionEnd>{sectionDescription}</LineDescriptionEnd>
              </div>
            ) : null}
          </section>
        );
      })}
      <div
        css={{
          position: 'absolute',
          bottom: mqs.phoneOnly ? -65 : -85,
          left: mqs.phoneOnly ? -23 : -33,
        }}
      >
        <img
          src={Rocket}
          alt="illustration fusée"
          css={{
            width: mqs.phoneOnly ? 50 : 70,
            height: mqs.phoneOnly ? 50 : 70,
            transform: 'rotate(180deg)',
          }}
        />
      </div>
    </div>
  );
};

export default SmallBreakdown;
