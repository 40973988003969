import React from 'react';
/* --- import Components ----*/
import { ColoredBox } from '../elements';
import { SentenceSmall } from 'src/components/Typography';
import { CurvedRightLineYellow, CurvedLeftLineYellow, CurvedRightLineGrey } from './curvedLines';
/* --- import Styles ----*/
import { mediaQueries } from 'src/style/breakpoints';
import colors from 'src/style/colors';

const BreakdownBoxes = ({ section }) => {
  let coloredBoxesList = [];

  if (section === 'bootcamp') {
    for (let i = 1; i < 11; i++) {
      if (i === 9 || i === 10) {
        coloredBoxesList.push(<ColoredBox key={i} color="yellow" />);
      } else {
        coloredBoxesList.push(<ColoredBox key={i} color="midGrey" />);
      }
    }
  } else if (section === 'alternance') {
    for (let i = 1; i < 74; i++) {
      if (i % 4 === 0 || i === 74) {
        coloredBoxesList.push(<ColoredBox key={i} color="yellow" />);
      } else {
        coloredBoxesList.push(<ColoredBox key={i} color="midGrey" />);
      }
    }
  } else {
    return null;
  }

  return (
    <div
      css={{
        position: 'relative',
        [mediaQueries.tabletLandscapeUp]: {
          padding: '0px 50px 0px 20px',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          gap: 3,
          flexWrap: 'wrap',
          width: 378,
          [mediaQueries.phoneOnly]: {
            width: 320,
          },
        }}
      >
        {coloredBoxesList}
      </div>
      {section === 'bootcamp' ? (
        <>
          <CurvedRightLineYellow />
          <SentenceSmall
            css={{
              paddingLeft: 10,
              paddingTop: 11,
              fontSize: 12,
              lineHeight: '17px',
              [mediaQueries.phoneOnly]: {
                fontSize: '12px',
              },
            }}
          >
            <span css={{ color: colors.yellow }}>2 dernières semaines</span>
            <br></br>Réalisation du MVP d'une vraie startup<br></br>(site Web ou app' Mobile)
          </SentenceSmall>
        </>
      ) : (
        <>
          <CurvedRightLineGrey />
          <CurvedLeftLineYellow />
          <SentenceSmall
            css={{
              paddingLeft: '17px',
              paddingTop: '11px',
              fontSize: '12px',
              lineHeight: '15px',
              [mediaQueries.phoneOnly]: {
                fontSize: '12px',
                paddingLeft: '0px',
              },
            }}
          >
            entreprise
          </SentenceSmall>
          <SentenceSmall
            css={{
              paddingLeft: 180,
              marginTop: '-15px',
              fontSize: 12,
              lineHeight: '15px',
              color: colors.yellow,
              [mediaQueries.phoneOnly]: {
                fontSize: 12,
                marginTop: '-40px',
                paddingLeft: 175,
              },
            }}
          >
            puis formation sur Zoom
          </SentenceSmall>
        </>
      )}
    </div>
  );
};

export default BreakdownBoxes;
