import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
/* --- import Components ----*/
import Layout from 'src/components/Layout';
import { Section } from '../../components/Section';
import { HeroHomeWrapper } from 'src/components/Hero/elements';
import HeroText from 'src/components/LandingPage/HeroText';
import { MainVideo } from 'src/components/Projects';
import { CTASection } from 'src/components/CTA';
import ApplyFormLanding from 'src/components/ApplicationForm/landingPage/ApplyFormLanding';
import TestimonialsSectionLandingPage from 'src/components/LandingPage/TestimonialsSectionLandingPage';
import ProgramLandingPage from 'src/components/LandingPage/Program/ProgramLandingPage';
import QALandingPage from 'src/components/QuestionsAnswer/QALandingPage';
import ButtonScrollToForm from 'src/components/LandingPage/ButtonScrollToForm';
import FeaturesSection from 'src/components/Bootcamp/FeaturesSection';
import AlternanceBreakdown from 'src/components/LandingPage/AlternanceBreakdownSection/AlternanceBreakdown';
/* --- import Utils ----*/
import { SessionDateDisplay, nextSessionsFilter } from 'src/utils/helpers';

const AlternanceLanding = ({ location, pageContext: { nextSessions } }) => {
  // image display
  const imageData = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "2023_studentsAndTeacher.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, placeholder: NONE)
        }
      }
      image2: file(relativePath: { eq: "2023_studentsAndTeacher4.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, placeholder: NONE)
        }
      }
      image3: file(relativePath: { eq: "2023_students.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, placeholder: NONE)
        }
      }
      video: file(relativePath: { eq: "2023_studentsAndTeacher3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  // next session
  const nextFullTimeSession = nextSessionsFilter(nextSessions, 'formation-developpeur-web-mobile');
  const date = SessionDateDisplay(nextFullTimeSession[0], 'Alternance');

  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C"
      pageName="Bootcamp full-time"
      pageType="landingPage"
      metaTitle="Le Reacteur | Apprendre à coder - Formation développeur Web et Mobile JavaScript"
      metaDescription="Apprenez à coder en rejoignant Le Reacteur, un coding bootcamp de 10 semaines, en présentiel (à Paris) ou à distance (en visio) et devenez Développeur Web et Mobile."
      css={{ paddingTop: 35 }}
    >
      {/* FIRST BANNER => form */}
      <Section theme="primary" landing hero>
        <HeroHomeWrapper landing>
          <HeroText fromPage="rncp6-alternance" />
          <ApplyFormLanding
            data={{
              bootcampType: 'Alternance',
              certification: 'RNCP6',
              nextSessions: nextFullTimeSession,
            }}
          />
        </HeroHomeWrapper>
      </Section>
      {/* SECOND BANNER => video */}
      <Section landing>
        <MainVideo
          mainThumbnail={imageData.video.childImageSharp.fluid}
          link="https://www.youtube.com/embed/59XH_-eMQhE"
          loopRef="59XH_-eMQhE"
          noModal
        />
        <ButtonScrollToForm text={`Je postule\u00A0!`} />
      </Section>
      {/* THIRD BANNER => Testimonials */}
      <TestimonialsSectionLandingPage />
      {/* FOURTH BANNER => Plan */}
      <AlternanceBreakdown />
      {/* FIFTH BANNER => Program */}
      <ProgramLandingPage fromPage="rncp6-alternance" />
      {/* SIXTH BANNER => Picto */}
      <FeaturesSection
        fromPage="rncp6-alternance"
        landing
        images={[
          imageData.image1.childImageSharp.gatsbyImageData,
          imageData.image2.childImageSharp.gatsbyImageData,
          imageData.image3.childImageSharp.gatsbyImageData,
        ]}
      />
      {/* SEVENTH BANNER => Ready to launch  */}
      <CTASection
        onBoardingPromise="15 à 17 mois d'alternance"
        course="Développeur Web et Mobile"
        nextSession={date}
        numberOfWeeks="10"
        format="en alternance"
        fromFormationsPageCertification="RNCP6"
        landing
      />
      {/* EIGHTH BANNER => FAQ  */}
      <QALandingPage fromPage="RNCP6-landing-alternance" />
    </Layout>
  );
};

export default AlternanceLanding;
