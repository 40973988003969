import React from 'react';
/* --- import Components ----*/
import { Section } from '../../Section';
import { SectionTitle } from '../../Typography';
import ButtonScrollToForm from '../ButtonScrollToForm';
import LargeBreakdown from './LargeBreakdown';
/* --- import Styles and Images ----*/
import { useMediaQueries } from 'src/utils/MediaQueries';
import SmallBreakdown from './SmallBreakdown';
import colors from 'src/style/colors';

const AlternanceBreakdown = () => {
  const mqs = useMediaQueries();

  const data = [
    {
      sectionName: 'bootcamp',
      sectionDescription: 'Début de la formation',
      sectionTitle: 'Bootcamp',
      tags: [
        { title: '10 semaines', color: 'purpleDark' },
        { title: '350h', color: 'purpleDark' },
        { title: 'Du\u00A0lundi au\u00A0vendredi', color: 'yellow' },
        { title: 'En\u00A0présentiel\u00A0(Paris) ou à\u00A0distance', color: 'lightGrey' },
      ],
      sectionText: `Acquisition des compétences nécessaires pour être productif dès les premiers jours au
      sein de votre entreprise d’accueil.`,
    },
    {
      sectionName: 'alternance',
      sectionDescription: 'Fin de la formation',
      sectionTitle: 'Modules avancés',
      tags: [
        { title: '13 à 15 mois', color: 'purpleDark' },
        { title: '371h', color: 'purpleDark' },
        { title: 'Du\u00A0lundi au\u00A0vendredi', color: 'yellow' },
        { title: 'En\u00A0entreprise ou en\u00A0formation', color: 'lightGrey' },
      ],
      sectionText: `Alternance entre périodes en entreprise (3\u00A0semaines) et sessions de formation
      avancée, à distance, via Zoom (1\u00A0semaine).`,
    },
  ];

  return (
    <Section landing theme="primary">
      <SectionTitle isMobileCenter isCenter css={{ marginBottom: 80, width: '100%' }}>
        Un&nbsp;
        <span css={{ color: colors.yellow }}>format&nbsp;idéal </span>
        pour apprendre&nbsp;à&nbsp;coder et&nbsp;trouver&nbsp;un&nbsp;emploi
      </SectionTitle>
      {mqs.tabletLandscapeUp ? <LargeBreakdown data={data} /> : <SmallBreakdown data={data} />}
      <ButtonScrollToForm css={{ paddingTop: '70px' }} text="Je postule !" />
    </Section>
  );
};

export default AlternanceBreakdown;
