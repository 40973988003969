import React from 'react';
/* --- import Components ----*/
import {
  BreakdownSection,
  BreakdownSectionText,
  BreakdownSectionTitle,
  LineDescriptionEnd,
  LineDescriptionStart,
} from '../elements';
import { PlainLine, RegularDottedLine } from 'src/components/Bootcamp/Schedule/TypicalDay';
import { TagContainer } from 'src/components/Tags/elements';
import Tag from 'src/components/Tags/Tag';
import BreakdownBoxes from './BreakdownBoxes';
/* --- import Styles and Images ----*/
import Rocket from 'src/data/images/new_rocket.svg';
import { mediaQueries } from 'src/style/breakpoints';

const LargeBreakdown = ({ data }) => {
  return (
    <div
      css={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        [mediaQueries.tabletLandscapeUp]: {
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'strech',
        },
      }}
    >
      {data.map((section) => {
        const { sectionName, sectionDescription, sectionTitle, tags, sectionText } = section;
        return (
          <React.Fragment key={sectionName}>
            <section
            key={sectionName}
              css={{
                width: 'calc((100% - 130px)/2)',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'flex-start',
                paddingTop: sectionName === 'alternance' && 70,
              }}
            >
              {sectionName === 'bootcamp' ? (
                <LineDescriptionStart>{sectionDescription}</LineDescriptionStart>
              ) : null}
              <BreakdownSection>
                {sectionName === 'bootcamp' ? (
                  <PlainLine />
                ) : (
                  <RegularDottedLine lineStyled="start" />
                )}
                <BreakdownSectionTitle>{sectionTitle}</BreakdownSectionTitle>
                <TagContainer landing css={{ padding: '0px 20px 10px 20px' }}>
                  <Tag color={tags[0].color} spacer>
                    {tags[0].title}
                  </Tag>
                  <Tag color={tags[1].color} spacer>
                    {tags[1].title}
                  </Tag>
                </TagContainer>
                <TagContainer landing css={{ padding: '0px 20px 10px 20px' }}>
                  <Tag color={tags[2].color} spacer>
                    {tags[2].title}
                  </Tag>
                </TagContainer>
                <TagContainer landing css={{ padding: '0px 20px 0px 20px' }}>
                  <Tag color={tags[3].color}> {tags[3].title}</Tag>
                </TagContainer>
                <BreakdownSectionText>{sectionText}</BreakdownSectionText>
                <BreakdownBoxes section={sectionName} />
              </BreakdownSection>
            </section>
            {sectionName === 'alternance' ? (
              <div
              key={sectionTitle}
                css={{
                  position: 'relative',
                  width: '130px',
                }}
              >
                <LineDescriptionEnd>{sectionDescription}</LineDescriptionEnd>
                <img
                  src={Rocket}
                  alt="illustration fusée"
                  css={{
                    width: 70,
                    height: 70,
                    transform: 'rotate(90deg)',
                    position: 'absolute',
                    top: '41px',
                    left: '10px',
                  }}
                />
              </div>
            ) : null}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default LargeBreakdown;
