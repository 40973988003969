/* --- import Styles ----*/
import { mediaQueries } from 'src/style/breakpoints';
import colors from 'src/style/colors';
import styled from 'src/style';

export const CurvedRightLineYellow = styled.div({
  width: 15,
  height: 35,
  borderStyle: 'dotted',
  borderColor: colors.yellow,
  borderWidth: 2,
  borderRadius: '50%',
  left: 134,
  top: 3,
  borderLeft: 'none',
  borderTop: 'none',
  borderBottom: 'none',
  position: 'absolute',
  transform: 'rotate(30deg)',
  [mediaQueries.tabletLandscapeUp]: {
    left: 154,
  },
  [mediaQueries.phoneOnly]: {
    left: 135,
  },
});

export const CurvedLeftLineYellow = styled.div({
  width: 40,
  height: 40,
  borderStyle: 'dotted',
  borderColor: colors.yellow,
  borderWidth: 2,
  borderRadius: '50%',
  left: 150,
  top: 50,
  borderLeft: 'none',
  borderTop: 'none',
  borderRight: 'none',
  position: 'absolute',
  transform: 'rotate(70deg)',
  [mediaQueries.tabletLandscapeUp]: {
    left: 170,
  },
});

export const CurvedRightLineGrey = styled.div({
  width: 20,
  height: 35,
  borderStyle: 'dotted',
  borderColor: colors.midGrey,
  borderWidth: 2,
  borderRadius: '50%',
  left: 76,
  top: 57,
  borderLeft: 'none',
  borderTop: 'none',
  borderBottom: 'none',
  position: 'absolute',
  transform: 'rotate(30deg)',
  [mediaQueries.tabletLandscapeUp]: {
    left: 96,
  },
  [mediaQueries.phoneOnly]: {
    left: 57,
    top: 75,
  },
});
